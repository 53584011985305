.thumb-wrap {
  position: relative;
  float: left;
  max-width: 200px;
  max-height: 100px;
  @include fade-transition;

  .img-thumbnail {
    max-height: 100px;
  }

  &:hover {
    .btn-remove {
        opacity: .5;
    }
  }

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    margin: 10px;
    border: 0;
    opacity: 0;
    @include fade-transition;

    &:hover {
        opacity: 1;
    }
  }
}
